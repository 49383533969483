<template>
  <div class="main">
    <el-divider></el-divider>
    <span style="font-size: 24px">Home Page Hot Product</span>
    <p>*已下架產品將不會顯示於主頁熱門產品中</p>
    <div>
      <table class="table table-striped">
        <thead class="thead-dark">
        <th>{{$t('Sequence')}}</th>
        <th>{{$t('ProductNumber')}}</th>
        <th>{{$t('ProductName')}}</th>
        <th>{{$t('PostStatus')}}</th>
        </thead>
        <draggable v-model="selectedProduct" class="table" tag="tbody">
          <tr v-for="(item,index) in selectedProduct" :key="item.productId" @click="removeHotProduct(item)">
            <td style="text-align: center">{{index +1}}</td>
            <td>{{ item.productCode }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.isStatus|productStatus }}</td>
          </tr>
        </draggable>
        <tr v-if="selectedProduct.length != 0">
          <td colspan="4" style="text-align: center"><el-button type="primary" @click="saveHotProduct">{{$t('Submit')}}</el-button></td>
        </tr>
        <tr v-if="selectedProduct.length == 0">
          <td colspan="4" style="text-align: center">No Data...</td>
        </tr>
      </table>
  </div>
    <el-divider></el-divider>
    <span style="font-size: 24px">Search</span>
    <div class="filter">
      <div class="item cl">
        <div class="label">{{$t('ProductNumber')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.productCode" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductName')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.title" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Departure')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.departureCity" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('PostStatus')}}：</div>
        <div class="fl">
          <el-select class="select" clearable v-model="filterData.isStatus" size="small" :placeholder="$t('All')">
            <el-option v-for="item in statusOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <button class="button button-primary small m-r" type="button" @click="getList()">{{$t('search')}}</button>
      <button class="button small" type="button" @click="clearFilterData">{{$t('Clear')}}</button>
    </div>
    <div class="txt-r">
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th>{{$t('ProductNumber')}}</th>
          <th>{{$t('ProductName')}}</th>
          <th>{{$t('Departure')}}</th>
          <th>{{$t('PostStatus')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) of list" :key="item.productId" @click="addProduct(item)">
          <td>{{item.productCode}}</td>
          <td>{{item.title}}</td>
          <td>{{item.departureCity}}</td>
          <td>{{item.isStatus|productStatus}}</td>
        </tr>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
import order from '../../../mixins/order';
import Empty from '../../../components/Empty';
import {languageOptions} from '../../../utils/language';
import {getProductList, saveHotProduct, getHotProduct} from '../../../api/admin-product';
// import Checkbox1 from '../../../components/Checkbox1';

import draggable from 'vuedraggable';
export default {
  name: 'HotProductSelect',
  components: {Empty, draggable},
  mixins: [order],
  data() {
    return {
      filterData: {
        productCode: '',
        title: '',
        departureCity: '',
        isStatus: '',
        page: 1,
        perPage: 10
      },
      statusOptions: [
        {value: 'unpublished', label: this.$t('Unpublished')},
        {value: 'published', label: this.$t('Published')},
        {value: 'obtained', label: this.$t('Obtained')}
      ],
      status: '',
      list: [],
      totalPages: 1,
      isEmpty: false,
      languageOption: languageOptions,
      ids: [], // 存放产品id
      all: false, // 是否全选
      idsArray: [],
      selectedProduct: []
    };
  },
  created() {
    window.sessionStorage.removeItem('productId');
    this.getList();
    this.getHotProduct();
  },
  watch: {
    'ids': function() {
      this.all = this.ids.length === this.list.length;
    }
  },
  methods: {
    getHotProduct(){
      getHotProduct().then(res => {
        this.selectedProduct = res.value.list;
      });
    },
    saveHotProduct(){
      if (this.selectedProduct.length !== 0){
        saveHotProduct(this.selectedProduct).then(res => {
          this.$message.success(res.message);
        });
      }
    },
    removeHotProduct(data){
      for (let i = 0; i < this.selectedProduct.length; i++) {
        if (this.selectedProduct[i].productCode === data.productCode){
          this.selectedProduct.splice(i, 1);
        }
      }
    },
    addProduct(data){
      if (this.selectedProduct.length < 10){
        if (this.selectedProduct.length === 0){
          this.selectedProduct.push(data);
          return;
        }
        for (let i = 0; i < this.selectedProduct.length; i++) {
          if (this.selectedProduct[i].productCode === data.productCode){
            this.$alert('Product already added to hot product', 'Notice', {
              confirmButtonText: 'OK'
            });
            return;
          }
        }
        this.selectedProduct.push(data);
      } else {
        this.$alert('Hot Product is full!', 'Notice', {
          confirmButtonText: 'OK'
        });
      }
    },
    // 获取产品列表
    getList(page = 1) {
      this.filterData.page = page;
      const data = this.filterData;
      getProductList(data).then(res => {
        const {value} = res;
        const list = value.list || [];
        this.list = list;
        this.totalPages = value.totalPages;
        this.isEmpty = data.page === 1 && !this.list.length;
      });
    },
    changePage(page) {
      this.getList(page);
    },
    optionAll() {
      if (this.all === false) {
        for (var i = 0; i < this.list.length; i++) {
          this.ids.push(this.list[i].productId);
        }
        // this.ids = this.idsArray;
      } else {
        this.ids = [];
      }
    },
    // 清除参数
    clearFilterData() {
      this.filterData = {
        productCode: '',
        title: '',
        departureCity: '',
        isStatus: '',
        page: 1,
        perPage: 10
      };
      this.getList();
    }
  }
};
</script>
<style scoped lang="less">
//@import "../../../assets/style/table";
table{
  width: 100%;
}
td, th{
  border: 1px solid #ddd;
  padding: 8px;
}
tr:nth-child(even){background-color: #f2f2f2;}
tr:hover {background-color: #ddd; cursor: pointer}
.main{
  .table{
    .link{color:#1890ff;cursor:pointer;}
  }
  .gap-line{color:#e7e7e7;}
  .pages{padding-left:0;padding-right:0;}
  .btn-edit{margin-right:8px;vertical-align:middle;cursor:pointer;}
}
.filter{
  .item{
    display:inline-block;margin-right:24px;margin-bottom:24px;vertical-align:top;
    .label{float:left;line-height:32px;}
  }
  .ipt{
    width:120px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
    &:focus{border-color:#ff6f61;}
  }
  /deep/ .select{width:120px;vertical-align:top;}
}
.checkbox-input{
  background-color: initial;
  cursor: default;
  appearance: checkbox;
  box-sizing: border-box;
  padding: initial;
  border: initial;
}
</style>
